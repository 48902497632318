import moment from 'moment-timezone';

/// moment.js 轉換的 format 筆記：
/// (1) HH:mm 表示時間格式為 24 小時
/// (2) hh:mm 表示時間格式為 12 小時
/// moment() = moment( new Date() ) => 非 UTC 的當下日期時間
/// moment().tz('Etc/UTC')          => UTC 的當下日期時間

export default {

  getDateSmaller(list_date = []) {
    if (list_date.length === undefined) return undefined;
    if (list_date.length === 0) return undefined;
    if (list_date.length === 1) return list_date[0];

    let _resultDate = list_date[0];
    for (let i = 1; i < list_date.length; i++) {
      if (moment(_resultDate).isAfter(list_date[i])) {
        _resultDate = list_date[i];
      }
    }

    // console.log( 'smaller date: ' + _resultDate )
    return _resultDate;
  },
  getDateBigger(list_date = []) {
    if (list_date.length === undefined) return undefined;
    if (list_date.length === 0) return undefined;
    if (list_date.length === 1) return list_date[0];

    let _resultDate = list_date[0];
    for (let i = 1; i < list_date.length; i++) {
      if (moment(_resultDate).isBefore(list_date[i])) {
        _resultDate = list_date[i];
      }
    }

    // console.log( 'bigger date: ' + _resultDate )
    return _resultDate;
  },

  getTimezoneNow() {
    return moment.tz.guess();
  },

  getDateNow(timezone = undefined, format = undefined) {
    if (timezone === undefined) timezone = this.getTimezoneNow(); // 如果沒給時區就猜測當下時區
    else if (timezone === 'UTC') timezone = 'Etc/UTC';

    if (format) return moment().tz(timezone).format(format);
    return moment().tz(timezone);
  },

  /**
     * countDays: 計算距離開始日期到結束日期的天數
     * @param {string} start - 開始日期，格式 YYYY-MM-DD
     * @param {string} [end=undefined] - 結束日期，格式 YYYY-MM-DD，沒填就預設為當天時間
     */
  countDays(start, end = undefined, timezone = undefined) {
    let _startDate; let
      _endDate;
    _startDate = moment(start);
    if (!end) _endDate = this.getDateNow(timezone); // moment(new Date());
    else _endDate = moment(end);
    // console.log('countDays after moment:')
    // console.log(_startDate);
    // console.log(_endDate);
    return _endDate.diff(_startDate, 'days') + 1; // +1 是因為不包含開始時間
  },

  countPastTime(start, end, unit = 'hours', showFloat = false, timezone = undefined) {
    let _startDate; let
      _endDate;
    _startDate = moment(start);
    if (!end) _endDate = this.getDateNow(timezone); // moment(new Date());
    else _endDate = moment(end);
    // console.log(_startDate);
    // console.log(_endDate);

    if (showFloat) return _endDate.diff(_startDate, unit, true).toFixed(1);
    return _endDate.diff(_startDate, unit) + 1; // +1 是因為不包含開始時間
  },

  getDateShift(start, shift, unit = 'days', format = 'YYYY-MM-DD HH:mm') {
    const _startDate = moment(start);
    return _startDate.add(shift, unit).format(format);
  },

  dateTimeZone(dateTime, timezone = 'Asia/Taipei', format = 'YYYY-MM-DD') {
    if (!dateTime) return dateTime;

    const _UTC = moment.tz(dateTime, 'Etc/UTC');
    // console.log( '_UTC' );
    // console.log( _UTC.format('YYYY-MM-DD HH:mm:ss') );
    // console.log( timezone );
    // console.log( _UTC.tz( timezone ).format('YYYY-MM-DD HH:mm:ss') );

    return _UTC.tz(timezone).format(format);
  },

  dateTimeZone_List(dateTimeList, timezone = 'Asia/Taipei', format = 'YYYY-MM-DD HH:mm') {
    const _newTimeList = [];

    dateTimeList.forEach((dateTime) => {
      if (!dateTime) {
        _newTimeList.push(dateTime);
      } else {
        const _UTC = moment.tz(dateTime, 'Etc/UTC');
        _newTimeList.push(_UTC.tz(timezone).format(format));
      }
    });
    return _newTimeList;
  },

  dataTimeZone_to_UTC(dateTime, timezone = 'Asia/Taipei', format = 'YYYY-MM-DD HH:mm:ss') {
    const _Local = moment.tz(dateTime, timezone);
    return _Local.tz('Etc/UTC').format(format);
  },

  dateFormat(dateTime, format = 'YYYY-MM-DD HH:mm') {
    if (!dateTime) {
      return dateTime;
    }

    return moment(dateTime).format(format);
  },

  timestampToDate(dateTime, format = 'YYYY-MM-DD HH:mm') {
    if (!dateTime) {
      return dateTime;
    }

    return moment.unix(dateTime).format(format);
  },

  dateFormat_List(dateTimeList, format = 'YYYY-MM-DD HH:mm') {
    const _newTimeList = [];

    dateTimeList.forEach((dateTime) => {
      if (!dateTime) {
        _newTimeList.push(dateTime);
      } else {
        _newTimeList.push(moment(dateTime).format(format));
      }
    });
    return _newTimeList;
  },
};
