import DateTools from '@/tools/date';
import * as CameraAPI from '@/apis/camera';

// initial state
const state = {
};

// getters
const getters = {

};

// actions
const actions = {

  get_Photos(context, settings) {
    return new Promise((resolve, reject) => {
      CameraAPI.api_Get_Photos(settings.query)
        .then((res) => {
          const _deviceData = res.data.photos;

          if (_deviceData !== undefined) {
            const _timezone = (settings.timezone ? settings.timezone : this.state.user.timezone);

            _deviceData.forEach((_item) => {
              // 資料從 UTC 轉換時區
              _item.log_time = DateTools.dateTimeZone(_item.log_time, _timezone, 'YYYY-MM-DD HH:mm:ss');
              _item.photo_time = DateTools.dateTimeZone(_item.photo_time, _timezone, 'YYYY-MM-DD HH:mm:ss');
            });
          }
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  get_Timelapses(context, settings) {
    return new Promise((resolve, reject) => {
      CameraAPI.api_Get_Timelapses(settings.query)
        .then((res) => {
          const _deviceData = res.data.timslapses;

          if (_deviceData !== undefined) {
            _deviceData.forEach((_item) => {

              // 資料從 UTC 轉換時區 - 縮時攝影不需要轉時區
              // _item.start_time = DateTools.dateTimeZone( _item.start_time, _item.timezone, 'YYYY-MM-DD HH:mm' )
              // _item.end_time = DateTools.dateTimeZone( _item.end_time, _item.timezone, 'YYYY-MM-DD HH:mm' )
            });
          }
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

};

// mutations
const mutations = {

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
